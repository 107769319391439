import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveMarginContainer from '../containers/responsiveMarginContainer';
import BlogPostThumnail from './BlogPostThumnail';
import UnderlinedText from '../text/underlinedText';

const useStyles = makeStyles(theme => ({
  container: {
     width: '100%',
     minHeight: '100vh',
    backgroundColor: '#FDFBFE',
  },
  grid: {
    paddingTop: 64,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gridColumnGap: 67,
    gridRowGap: 96,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto',
    }
  },
  largeText: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 72,
    lineHeight: '120%',
    paddingBottom: 48,
  },
  imageContainer: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
  }
}));

export default function AllBlogPostsSection({ blogPosts, blogCategory }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ResponsiveMarginContainer   
          desktopSizes={{
            width: '92%',
            top: 64,
            bottom: 64,
          }}
          mobileSizes={{
            width: '88%',
            top: 64,
            bottom: 64,
          }}
        >
        <UnderlinedText>
          Allt om {blogCategory}
        </UnderlinedText>
        <div className={classes.grid}>
          {blogPosts.map(blogPost => {
            return (
              <BlogPostThumnail key={blogPost.data.title} blogPost={blogPost}/>
            )
          })}
        </div>
      </ResponsiveMarginContainer>
    </div>
  );
}
