import React from 'react';
import Navbar from '../components/navbar/navbar';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/footer/footer';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import BlogCategoryBredcrums from '../components/blog/BlogCategoryBredcrums';
import BlogCategoryHero from '../components/blog/BlogCategoryHero';
import Img from "gatsby-image"
import AllBlogPostsSection from '../components/blog/AllBlogPostsSection';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 67,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 100,
    },
    width: '100%',
    backgroundColor: theme.palette.primaryPink.main,
  },
}));

const BlogCategoryPage = ({location, data}) => {

  // const blogCategoryPageData = data.prismicBlogcategorypage.data;
  // const blogCategory = blogCategoryPageData.blog_category.document.data;
  const classes = useStyles();
  const blogCategoryPageData = data.allPrismicBlogcategorypage.nodes[0].data;
  const blogPageCategoryUid = blogCategoryPageData.blog_category.document.uid;
  const blogPosts = blogPageCategoryUid === 'allt'?
    data.allBlogPosts.nodes :
    data.blogPostsForCategory.nodes;

  const breadcrums = data.breadcrums.nodes.map(crum => {
    return {
      url: crum.url,
      name: crum.data.blog_category.document.data.display_name,
      uid: crum.data.blog_category.document.uid
    }
  })


  return (
    <>
      <SEO
        title={blogCategoryPageData.meta_title}
        description={blogCategoryPageData.meta_description}
        socialImage={blogCategoryPageData.social_image.url}
      /> 
      <Navbar currentPath={location.pathname} />
      <div className={classes.container}>
        <BlogCategoryBredcrums selected={blogPageCategoryUid} crums={breadcrums} />
        <BlogCategoryHero
            largeText={blogCategoryPageData.large_text}
            infoTextHtml={blogCategoryPageData.description_text.html}
            image={<Img fluid={blogCategoryPageData.image.fluid} />}
        />
        <AllBlogPostsSection 
          blogPosts={blogPosts}
          blogCategory={blogCategoryPageData.blog_category.document.data.display_name}
        />
      </div>
      <Footer colorTheme="dark" paddingTop={48} />
    </>
  );
}

export const query = graphql`
  query BlogCategoryPageQuery($uid: String) {
    breadcrums: allPrismicBlogcategorypage(sort: {fields: data___blog_category___uid}) {
      nodes {
        url
        data {
          blog_category {
            document {
              ... on PrismicBlogcategory {
                uid
                data {
                  display_name
                }
              }
            }
          }
        }
      }
    }
    allPrismicBlogcategorypage(filter: {data: {blog_category: {uid: {eq: $uid}}}}) {
      nodes {
        data {
          meta_title
          meta_description
          social_image {
            url
          }
          blog_category {
            document {
              ... on PrismicBlogcategory {
                uid
                data {
                  display_name
                }
              }
            }
          }
          large_text
          description_text {
            html
          }
          image {
            fluid(maxWidth: 553, maxHeight: 553) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
    blogPostsForCategory: allPrismicBlogPost(filter: {data: {blog_category: {uid: {eq: $uid}}}}) {
      nodes {
        url
        data {
          title
          short_description
          thumbnail_image {
            fluid(maxWidth: 553, maxHeight: 553) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
    allBlogPosts: allPrismicBlogPost(sort: {fields: data___published_date, order: DESC}) {
      nodes {
        url
        data {
          title
          short_description
          thumbnail_image {
            fluid(maxWidth: 553, maxHeight: 553) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

export default BlogCategoryPage;