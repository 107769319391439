import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveMarginContainer from '../containers/responsiveMarginContainer';
import HtmlTextRenderer from '../../prismic/components/HtmlTextRenderer';

const useStyles = makeStyles(theme => ({
  container: {
     width: '100%',
     backgroundColor: theme.palette.primaryPink.light1
  },
  innerContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',
    gridColumnGap: 96,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'minmax(200px, 703px) minmax(200px, 553px)',
      gridTemplateRows: 'auto',
    }
  },
  largeText: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: '120%',
    paddingBottom: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 72,
      paddingBottom: 48,
    },
  },
  imageContainer: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
  }
}));

export default function BlogCategoryHero({ largeText, infoTextHtml, image }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <ResponsiveMarginContainer   
          desktopSizes={{
            width: '82%',
            top: 128,
            bottom: 64,
          }}
          mobileSizes={{
            width: '88%',
            top: 48,
            bottom: 32,
          }}
        >
        <div className={classes.innerContainer}>
          <div>
            <div className={classes.largeText}>
              {largeText}
            </div>
            <div className={classes.infoText}>
              <HtmlTextRenderer>
                {infoTextHtml}
              </HtmlTextRenderer>
            </div>
          </div>
          <div className={classes.imageContainer}>
            {image}
          </div>
        </div>
      </ResponsiveMarginContainer>
    </div>
  );
}
