import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Img from 'gatsby-image'
import InternalLink from '../stuff/InternalLink';
import {motion} from 'framer-motion'

const useStyles = makeStyles(theme => ({
  container: {
     width: '100%',
     height: '100%',
     backgroundColor: theme.palette.primaryPink.light1,
     display: 'flex',
     flexDirection: 'column',
     alignItems: 'center',
     justifyContent: 'center',
  },
  imageContainer: {
    paddingTop: 32,
    paddingBottom: 32,
    height: '100%',
    width: '70%',
  },
  textContainer: {
    height: '100%',
    width: '80%',
  },
  title: {
    paddingBottom: 12,
  },
  link: {
    paddingTop: 24,
    paddingBottom: 64,
  }
}));

export default function BlogPostThumnail({ blogPost }) {
  const classes = useStyles();
  return (
    <InternalLink to={blogPost.url}>
      <motion.div 
        initial={{
          scale: 1,
          background: 'linear-gradient(180deg, #FAF2FC 0%, #FAF2FC 100%)',
        }}
        whileHover={{
          scale: 1.05,
          background: 'linear-gradient(163.81deg, #EFD9F7 -59.25%, #FAF2FC 128.68%)',
        }}
        whileTap={{
          scale: 0.98
        }}
        className={classes.container}>
        <div className={classes.imageContainer}>
          <Img imgStyle={{ objectFit: 'contain' }} style={{height: '100%', maxHeight: 260}} fluid={blogPost.data.thumbnail_image.fluid}/>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.title} variant="h4">
            {blogPost.data.title}
          </Typography>
          <Typography variant="body1" >
            {blogPost.data.short_description}
          </Typography>
          <div className={classes.link}>
            <Typography variant="button">
              Läs här
            </Typography>
          </div>
        </div>
      </motion.div>
    </InternalLink>
  );
}
