import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InternalLink from '../stuff/InternalLink';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: 78,
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  }
}));

export default function BlogCategoryBredcrums({ selected, crums }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
        <div className={classes.innerContainer}>          
          {crums.map(crum => {
            return (<InternalLink key={crum.name} to={crum.url}>
              <Typography style={{textTransform: 'uppercase', fontWeight: selected === crum.uid? 'bold' : 'normal'}} variant="h6">
                {crum.name}
              </Typography>
            </InternalLink>)
          })}
        </div>
    </div>
  );
}
